<template>
  <core-view fill-height class="view">
    <template>
      <div v-if="!loading" class="center">
        <v-form ref="form">
          <div class="linha">
            <btn-auto-complete
              label="Operador"
              :redirect="rotaFiltros[0]"
              :dados="autocomplete.operador"
              v-model="formulario.laaidoperador"
              :returnObject="false"
              item-label="pesnome"
              item-value="pesidpessoa"
              :readonly="true"
              :rules="rules.laaidoperador"
            />
          </div>
          <div class="linha">
            <btn-auto-complete
              label="Propriedade"
              icon="mdi-chevron-down"
              :dados="autocomplete.propriedade"
              v-model="formulario.laaidpropriedade"
              :returnObject="false"
              item-label="pronome"
              item-value="proidpropriedade"
              :readonly="true"
              :rules="rules.laaidpropriedade"
            />
          </div>
          <div class="linha">
            <btn-auto-complete
              label="Talhão"
              :dados="autocomplete.talhao"
              icon="mdi-chevron-down"
              v-model="formulario.laaidtalhao"
              :returnObject="false"
              item-label="taldescricao"
              item-value="talidtalhao"
              :readonly="readonly"
              ref="talhao"
            />
          </div>
          <div class="linha">
            <btn-auto-complete
              label="Atividade"
              :dados="autocomplete.atividade"
              icon="mdi-chevron-down"
              v-model="formulario.laaidatividade"
              :returnObject="false"
              item-label="atadescricao"
              item-value="ataidatividadeabastecimento"
              :readonly="readonly"
              :rules="rules.laaidatividade"
            />
          </div>
          <div class="linha">
            <core-date-time-picker-field
              hide-details
              form
              :readonly="readonly"
              v-model="formulario.laadatainicio"
              :label="['Data inicio', 'Hora']"
              :rules="rules.laadatainicio"
            />
          </div>
          <div class="linha">
            <core-date-time-picker-field
              hide-details
              form
              :readonly="readonly"
              v-model="formulario.laadatatermino"
              :clearable="true"
              ref="datatermino"
              :label="['Data termino', 'Hora']"
              :rules="rules.laadatatermino"
              :sem-valor="semvalor"
              @limpatermino="$_terminoResetaValor"
            />
          </div>
          <div class="linha">
            <v-text-field
              label="Observação"
              outlined
              :readonly="readonly"
              hide-details="auto"
              :style="{ 'background-color': 'white' }"
              v-model="formulario.laaobservacao"
            >
            </v-text-field>
          </div>

          <br/>

          <div class="divinferior">
            <v-btn
              v-if="!readonly"
              height="45"
              class="green botaoinferior"
              @click="$_salvaDados"
            >
              Salvar
            </v-btn>
          </div>
        </v-form>
      </div>
    </template>
  </core-view>
</template>

<script>
import CoreView from "@/components/core/view/Index.vue";
import BtnAutoComplete from "@/components/core/btn-auto-complete/Index.vue";
import { ROUTE_APONTAMENTO, ROUTE_OPERADOR } from "@/constants/ROUTES";
import errorHandler from "@/utils/error-handler";
import db from "@/plugins/dexie"
import { LANCAMENTO_STORAGE } from '@/constants/STORAGE';
import { SetupWebSocket, WsEnviaDados } from '@/utils/ws/websocket';

import CoreDateTimePickerField from '@/components/core/variant/date-time-picker-field variant/Index.vue';
import geraData from '@/utils/geradata';

//
function getSessionStorageParse(key, defaultValue) {
  const retorno = sessionStorage.getItem(key) ?? defaultValue;
  try {
    return JSON.parse(retorno);
  } catch {
    return retorno;
  }
}
//
export default {
  name: "APCadastro",
  components: {
    CoreView,
    BtnAutoComplete,
    CoreDateTimePickerField,
  },
  data() {
    return {
      rotaFiltros: [ROUTE_OPERADOR],
      loading: true,
      readonly: false,
      idatividade: null,
      autocomplete: {
        atividade: null,
        operador: null,
        propriedade: null,
      },
      formulario: {
        laaidatividade: '',
        laaidoperador: '',
        laaidpropriedade: '',
        laaidtalhao: '',
        laadatainicio: geraData(),
        laadatatermino: geraData(),
        laaobservacao: '',
        //
        laadatacriacao: geraData(),
        laaidusuario: getSessionStorageParse('user:useidnuvem', null),
        laadataatualizacao: geraData(),
        laaidnuvem: '',
      },
      rules: {
        laaidatividade: [v => !!v || 'Atividade não pode ser vazio'],
        laaidoperador: [v => !!v || 'Operador não pode ser vazio'],
        laaidpropriedade: [v => !!v || 'Propriedade não pode ser vazio'],
        // laaidtalhao: [v => !!v || 'Talhão não pode ser vazio'],
        laadatainicio: [
          v => !!v || 'Data de inicio não pode ser vazia', 
          v => {
            if (this.formulario.laadatatermino) {
              (v < this.formulario.laadatatermino) || 'Data de inicio não pode ser maior que a data de termino'
            }
          }
        ],
        laadatatermino: [
          v => {
            if (v) {
              (v > this.formulario.laadatainicio) || 'Data de termino não pode ser menor que a data de inicio'
            }
          }
        ],
      },
      semvalor: false,
    };
  },

  methods: {
    async $_salvaDados() {
          if (!this.$refs.form.validate()) {
              errorHandler("Formulário invalido.");
              return;
          }
          const formFromStorage = await JSON.parse(sessionStorage.getItem(LANCAMENTO_STORAGE.formulario, this.formulario));
          console.log(formFromStorage, 'formFromStorage');
          if (this.idatividade) {
              // UPDATE
              db.lancamentoatividade.update(Number(this.idatividade), formFromStorage);
          } else {
              // CREATE
              db.lancamentoatividade.add(formFromStorage);
              WsEnviaDados({tipo: 'postLancamentoAtividade', msg: [formFromStorage] });
              }
              sessionStorage.removeItem(LANCAMENTO_STORAGE.formulario);

              this.$router.replace({
              ...this.$route,
              name: ROUTE_APONTAMENTO.name,
          });
    },
    
    async $_setupAutoComplete() {
      this.autocomplete.atividade = await db.atividadeabastecimento.toArray();
      this.autocomplete.operador = await db.pessoa.toArray();
      this.autocomplete.propriedade = await db.propriedade.toArray();
      this.autocomplete.talhao = await db.talhao.toArray();
      this.loading = false;
      // Foco Inicial
    },
    
    async $_storageToForm() {
      if (!sessionStorage.getItem(LANCAMENTO_STORAGE.formulario)) {
        setTimeout(() => {
          this.$_terminoResetaValor();
        }, 200);
      } else {
        const formFromStorage = JSON.parse(sessionStorage.getItem(LANCAMENTO_STORAGE.formulario, this.formulario));
        if (formFromStorage.laadatatermino) {
          this.formulario = { ...this.formulario, ...formFromStorage };
        } else {
          this.formulario = { ...this.formulario, ...formFromStorage, laadatatermino: geraData() };
          if (!formFromStorage.laadatatermino) {
            setTimeout(() => {
              this.$_terminoResetaValor();
            }, 200);
          }
        }
      }
    },
    
    async $_formToStorage() {
      if (this.readonly) {
        return;
      }
      setTimeout(() => {
        if (this.$$_terminoTemValor()) {
          sessionStorage.setItem(LANCAMENTO_STORAGE.formulario, JSON.stringify(
              { ...this.formulario }
          ));
          this.semvalor = false;
        } else {
          sessionStorage.setItem(LANCAMENTO_STORAGE.formulario, JSON.stringify(
              { ...this.formulario, laadatatermino: null }
          ));
          this.semvalor = true;
        }
      })
    },

    async $_setupFields(id, readonly) {
      this.readonly = !!readonly;
      const formFromDexie = await db.lancamentoatividade.where({
        id: Number(id),
      }).first();
      if (formFromDexie.laadatatermino) {
        this.formulario = { ...this.formulario, ... formFromDexie };
      } else {
        this.formulario = { ...this.formulario, ... formFromDexie, laadatatermino: geraData() };
        if (!formFromDexie.laadatatermino) {
          setTimeout(() => {
            this.$_terminoResetaValor();
          }, 200);
        }
      }
      this.formulario.laadataatualizacao = geraData();
      if (readonly) {
        Object.keys(this.rules).forEach((key) => {
          this.rules[key] = [() => true];
        });
      }
    },

    async $_terminoResetaValor() {
      console.log('aaa');
      if (this.$$_terminoTemValor()) {
        // Mesmo depois do clique terminoTemValor ainda está voltando true;
        await this.$$_terminoTemValor().firstElementChild.click();
        this.semvalor = true;
        this.$nextTick(() => {
          this.$refs.talhao.$children[0].focus();
          this.$_formToStorage();
        })
      } else {
        this.semvalor = false;
      }
    },

    $$_terminoTemValor() {   
      const input = this.$refs.datatermino.$el;
      const clearButton = input.querySelector('.v-input__icon.v-input__icon--clear');
      console.log(!!clearButton);
      return clearButton;
    }
  },
  watch: {
    formulario: {
      deep: true,
      handler() {
        this.$_formToStorage()
      }
    },
  },
  created() {
    this.$_setupAutoComplete();
    this.idatividade = sessionStorage.getItem(LANCAMENTO_STORAGE.idlancamento);
    const readonly = sessionStorage.getItem(LANCAMENTO_STORAGE.readonly);
    if (this.idatividade) {
      this.$_setupFields(this.idatividade, readonly);
      return;
    }
    this.$_storageToForm();
    SetupWebSocket();
  },
};
</script>

<style scoped>
.center {
  text-align: center;
  width: 100%;
}
.linha {
  display: flex;
  justify-content: space-around;
}
.divinferior {
  text-align: center;
  width: 100%;
  bottom: 5px;
  right: 0%;
}

.botaoinferior {
  color: white;
}

.input-data {
  width: 110%;
}

.view {
  max-width: 450px;
}

</style>