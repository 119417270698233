import * as ROUTES from './ROUTES';

export const MENU_HOME = {
  icon: 'mdi-home-outline',
  label: ['Home'],
  route: ROUTES.ROUTE_HOME.path,
};

export const MENU_ABASTECIMENTO = {
  icon: 'mdi-gas-station',
  label: ['Abastecimento'],
  route: ROUTES.ROUTE_ABASTECIMENTO,
};

export const MENU_APONTAMENTO = {
  icon: 'mdi-account-clock',
  label: ['Atividade'],
  route: ROUTES.ROUTE_APONTAMENTO,
};

export const MENU_VISITA = {
  icon: 'mdi-flask',
  label: ['Visita Técnica'],
  route: ROUTES.ROUTE_VISITA,
}


export const MENU_NFE = {
  icon: '$vuetify.icons.NFe',
  label: ['NFe'],
  route: ROUTES.ROUTE_NFE,
};
// ORDEM DO MENU

export default [
  MENU_HOME,
  MENU_ABASTECIMENTO,
  MENU_APONTAMENTO,
  MENU_NFE,
  MENU_VISITA,
];

export function menuMapper(componentInstance, menus) {
  return [].concat(menus).map((menu) => ({
    ...menu,
    label: String(...menu.label),
  }));
}
