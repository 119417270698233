import Dexie from 'dexie';

const db = new Dexie('AgroCampo');

db.version(11).stores({
  usuario: `
    ++id,
    useidnuvem,
    usenome,
    usecpf,
    usesenha,
    usedataatualizacao
  `,
  veiculo: `
    ++id,
    veiidveiculo,
    veiidnuvem,
    veiplaca,
    veitipoveiculo,
    veimarca,
    veicapacidade,
    veifrota,
    veidataatualizacao,
    veitipomaquinario
  `,
  propriedade: `
    ++id,
    proidnuvem,
    proidpropriedade,
    pronome,
    prodataatualizacao
  `,
  operacao: `
    ++id,
    opeidnuvem,
    opeidoperacao,
    opedescricao,
    opedataatualizacao
  `,
  produto: `
    ++id,
    proidnuvem,
    proidproduto,
    prodescricao,
    procodigo,
    provalor,
    prodataatualizacao
  `,
  estoque: `
    ++id,
    moeidnuvem,
    moeidmovimentacaoestoque,
    moedescricao,
    moecategoria,
    moeidproduto,
    moedataatualizacao
  `,
  localmovimentacaoestoque: `
    ++id,
    lmeidnuvem,
    lmeidlocalmovimentacaoestoque,
    lmedescricao,
    lmeidproduto,
    lmedataatualizacao
  `,
  pessoa: `
    ++id,
    pesidnuvem,
    pesidpessoa,
    pesnome,
    pesnomefantasia,
    pescpf,
    pesdataatualizacao
  `,
  atividadeabastecimento: `
    ++id,
    ataidnuvem,
    ataidatividadeabastecimento,
    atadescricao,
    atadataatualizacao
  `,
  abastecimento: `
    ++id,
    abaidnuvem,
    abadata,
    abaoperacao,
    abafrota,
    abaoperadorveiculo,
    abaodometro,
    abatipoodometro,
    abaquantidade,
    abaorigem,
    abaoperador,
    abalocal,
    abaatividade,
    abadescricao,
    abafinalizado,
    abaproduto
  `,
  logexclusao: `
    ++id,
    loedataexclusao
  `,
  movimentacaoabastecimento: `
    ++id,
    moatipooperacao,
    moaidveiculo,
    moaidpessoa,
    moatipoodometro,
    moaodometro,
    moaquantidade,
    moaidprodutoorigem,
    moaidpessoaorigem,
    moaidpropriedade,
    moaidatividade,
    moaobservacao,
    moaidlocalarmazenamentoorigem,
    moadatacriacao,
    moaidusuario,
    moaidnuvem
  `,
  lancamentoatividade: `
    ++id,
    laaidpropriedade,
    laaidatividade,
    laaidoperador,
    laadatacriacao,
    laaidnuvem,
    laaidusuario,
    laaidtalhao,
    laadatainicio,
    laadatatermino,
    laaobservacao,
    laadataatualizacao
  `,
  cultura: `
    ++id,
    culidnuvem,
    culidcultura,
    culdescricao,
    culidpropriedade,
    culdataatualizacao
  `,
  visitatecnica: `
    ++id,
    vitidnuvem,
    vitidusuario,
    vitidcultura,
    vitidprodutor,
    vitidpropriedade,
    vitdatacriacao
  `,
  visita: `
    ++id,
    visidnuvem,
    visidcultura,
    visidprodutor,
    visidpropriedade,
    visdatacriacao
  `,
  imagem: `
    ++id,
    idvisita,
    imgdata,
    imgdataanterior,
    imgnome,
    imgdescricao
  `,
  naturezaoperacao: `
    ++id,
    naoidnaturezaoperacao,
    naodescricao,
    naodataatualizacao
  `,
  notafiscal: `
    ++id,
    nofidnotafiscal,
    nofidnaturezaoperacao,
    nofdataatualizacao,
    nofidpessoa,
    nofidproduto,
    nofun,
    nofquantidade,
    nofvalorunitario,
    noftotal,
    nofidtransportador,
    noftipofrete,
    nofidveiculo,
    nofespecie,
    nofdataemissao,
    nofnronota,
    nofserie,
    nofnomepessoa,
    nofplaca,
    nofidnuvem,
    nofidusuario,
    nofmensagemretornanfesefaz,
    nofcodigoretornanfesefaz,
    nofdatacriacao,
    nofprocessamento
  `,
  notafiscalitem: `
    ++id,
    nronota,
    nfidescricao,
    nfidataatualizacao
  `,
  talhao: `
    ++id,
    talidtalhao,
    talidnuvem,
    taldescricao,
    taldataatualizacao
  `,
});

export default db;
