import store  from '../store';
import db from '@/plugins/dexie';
import { TOKEN, USER_STORAGE } from '@/constants/STORAGE'

function getSessionStorage(key,defaultValue) {
  return sessionStorage.getItem(key) ?? defaultValue;
}
export default async function conexao() {
  // Login do Usuário caso se conectou offline.
  const authToken = getSessionStorage(TOKEN, '');
  const useridnuvem = getSessionStorage(USER_STORAGE.useidnuvem,'');
  const payload = await db.usuario.where({useidnuvem: useridnuvem}).first();
  if(!authToken) {
    try {
      const user = {
        cpf: payload.usecpf,
        password: payload.usesenha,
      }
      store.dispatch('auth/authenticate', user)
      
    } catch (err) {
      console.log(err)
    }
  }
  // Atualização das notas que foram emitidas enquanto offline
  const processando = await db.notafiscal.where({ nofprocessamento: 'true' });
  const semcodigo = [];
  for (const item of (await processando.toArray())) {
    if (item.nofcodigoretornanfesefaz) {
      await db.notafiscal.where({ id: item.id }).modify({nofprocessamento: 'false'});
    } else {
      semcodigo.push(item)
    }
  }
  if(semcodigo.length > 0) {
    store.dispatch('notafiscal/verificarEmissao', { body: semcodigo });
  }
}