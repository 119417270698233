/* Funcao padrao para gerar datas no app */

import moment from "moment-timezone";

export default function geraData(date = new Date(), mascara = 'YYYY-MM-DD HH:mm:ss.SSS', timezone = 'America/Sao_Paulo') {
    if (isNaN(new Date(date))) {
        return null;
    } else {
        return moment(date).tz(timezone).format(mascara);
    }
}