export const TOKEN = 'auth:token';

export const TOKENLOCAL = {
  tokenlocal: 'auth:tokenlocal'
}

export const MODULOS_STORAGE = {
  modulos: 'permitidos',
};

export const USER_STORAGE = {
  usesenha: 'use-senha',
  localtoken: 'localtoken',
  useidnuvem: 'user:useidnuvem'
};

export const ABASTECIMENTO_STORAGE = {
  operacao: 'abastecimento:operacao',
  frota: 'abastecimento:frota',
  operadora: 'abastecimento:operadora',
  odometro: 'abastecimento:odometro',
  quantidade: 'abastecimento:quantidade',
  origem: 'abastecimento:origem',
  operadorb: 'abastecimento:operadorb',
  atividade: 'abastecimento:atividade',
  tipo: 'abastecimento:tipo',
  propriedade: 'abastecimento:propriedade',
  produto: 'abastecimento:produto',
  valor: 'abastecimento:valor',
  descricao: 'abastecimento:descricao',
  data: 'abastecimento:data',
  idabastecimento: 'abastecimento:idabastecimento',
  createdBefore: 'abastecimento:createdBefore',
  createdFrom: 'abastecimeto:createdFromr'
};

export const FROTA_STORAGE = {
  tipo: 'frota:tipo',
  placa: 'frota:placa',
};

export const OPERADOR_STORAGE = {
  tipo: 'operador:tipo',
};

export const ORIGEM_STORAGE = {
  parametro: 'apagar:parametro',
  page: 'apagar:page',
  sort: 'apagar:sort',
  search: 'apagar:search',
  textofiltro: 'apagar:textofiltro',
};

export const LANCAMENTO_STORAGE = {
  createdBefore: 'lancamento:createdBefore',
  createdFrom: 'lancamento:createdFrom',
  idlancamento: 'lancamento:idlancamento',
  formulario: 'lancamento:formulario',
  filtropessoa: 'lancamento:filtropessoa',
  filtropropriedade: 'lancamento:filtropropriedade'
}

export const ROTA_STORAGE = {
  anterior: 'rota:anterior',
  proxima: 'rota:proxima',
  cadastro: 'rota:cadastro'
}

export const CULTURA_STORAGE = {
  propriedade: 'cultura:propriedade',
  descricao: 'cultura:descricao',
}

export const VISITA_STORAGE = {
  idvisita: 'visita:idvisita',
  produtor: 'visita:produtor',
  cultura: 'visita:cultura',
  propriedade: 'visita:propriedade',
  data: 'visita:data',
  createdBefore: 'visita:createdBefore',
  createdFrom: 'visita:createdFrom'
}

export const VISITA_INFO_STORAGE = {
  idinfo: 'visitainfo:idinfo',
  titulo: 'visitainfo:titulo',
  observacao: 'visitainfo:observacao',
  imagem: 'visitainfo:imagem',
  alterar: 'visitainfo:alterar',
  alterartitulo: 'visitainfo:alterartitulo',
  alterarobservacao: 'visitainfo:alterarobservacao'
}

export const NFE_STORAGE = {
  formulario: 'nfe:formulario',
  filtropessoa: 'nfe:filtropessoa',
  datacriacao: 'nfe:datacriacao',
  createdBefore: 'nfe:createdBefore',
  createdFrom: 'nfe:createdFrom'
};