import db from "@/plugins/dexie";
import { WsEnviaDados } from "../../websocket";
import geraData from "@/utils/geradata";

export default {
    enviaLancamentoAtividade: () => {
        const lancamentos = db.lancamentoatividade.where('laaidnuvem').equals('null').toArray();

        lancamentos.then((tabela) => {
            tabela.forEach((laa) => {
                WsEnviaDados({tipo: 'postLancamentoAtividade', msg: laa})
            });
        });
    },
    retornoLancamentoAtividade: ({ data }) => {
        const item = data.body;
        // query pela DataCriacao para fazer um handshake entre a WS para atribuir o idnuvem no lancamento
        const queryPromise = db.lancamentoatividade.where('laadatacriacao').equals(geraData(item.laadatacriacao)).toArray();
        queryPromise.then((tabela) => {
            tabela.forEach((linha) => {
                db.lancamentoatividade.update(linha.id, {laaidnuvem: item.id})
            })
        })
    },
}