<template>
  <app-card-apontamento-skeleton v-bind="$attrs" v-on="$listeners" :actions="actions"
  @click="$_emitter">
    <template #contrato >

    <div class="card">
      <!-- <div v-if="value.id" class="idapontamento"> {{ value.id }} </div> -->
      <div class="card__header">
        <div class="header__periodo">
          <div v-if="value.laadatainicio" class="periodo__datas">
            <span class="periodo__label">Inicio: </span>
            <span>
              {{$_formataData(value.laadatainicio,'DD/MM/YYYY')}}
            </span>
            <span class="periodo__hora">
              {{$_formataData(value.laadatainicio,'HH:mm')}}
            </span>
          </div>
          <div class="digito-a">A</div> 
          <div v-if="value.laadatatermino" class="periodo__datas">
            <span class="periodo__label">Término: </span>
            <span>
              {{$_formataData(value.laadatatermino, 'DD/MM/YYYY')}}
            </span>
            <span class="periodo__hora">
              {{$_formataData(value.laadatatermino,'HH:mm')}}
            </span>
          </div>
          <div v-else>
            {{ '--/--/--- --:--' }}
          </div>
        </div>
        <v-btn icon class="header__btn-editar">
          <v-icon large @click="$emit('onClickIcon', value.id)">
            mdi-pencil-box-outline
          </v-icon>
        </v-btn> 
      </div>
      <!-- <div v-if="value.operador">- {{value.operador.pesnome}} </div> -->
    </div>
    </template>
    <template #detalhe1>
      <span  v-if="value.talhao"> {{value.talhao.taldescricao}}</span>
      <span v-if="value.atividade" class="texto-atividade"> {{value.atividade.atadescricao}}</span>
      <div v-if="value.laaobservacao"> {{value.laaobservacao}}</div>
      <!-- <div  v-if="value.propriedade"> {{value.propriedade.pronome}}</div> -->
    </template>
  </app-card-apontamento-skeleton>
</template>

<script>
import moment from 'moment-timezone';
import AppCardApontamentoSkeleton from './Skeleton.vue';

export default {
  name: 'AppCardApontamento',

  components: {
    AppCardApontamentoSkeleton,
  },

  methods: {
    $_formataData(date, mascara) {
      return moment.tz(date, 'America/Sao_Paulo').local().format(mascara);
    },

    $_emitter() {
      this.$emit('innerClick', this.value.id);
    },
  },

  props: {
    value: Object,
    actions: Boolean,
  },
};
</script>

/<style scoped>
#principal{
  display: block;
}

.flex-container {
  display: flex;
}

.flex-container > * {
  margin-right: 3px;
}

.idapontamento {
  margin-right: 10px;
}

.card__header {
  display: flex;
}
.header__periodo {
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding-right: 25px;
  flex-wrap: wrap;
}

.header__periodo > div {
  display: flex;
}

.periodo__label {
  display: none;
}

.periodo__hora {
  transform: translateX(5px);
}


@media screen and (max-width: 377px) {
  .header__periodo {
      justify-content: space-between;
  }
  .periodo__datas:first-child > .periodo__label {
    margin-right: 28px;
  }
  .periodo__label {
    display: block;
    margin-right: 10px;
  }
  .digito-a {
    visibility: hidden;
  }
}

@media screen and (min-width: 550px) {
    .header__periodo {
        max-width: 400px;
    }
}

.header__btn-editar:hover {
  color: #4CAF50;
}

.status{
    color: rgb(4, 156, 4);
    text-align: right;
    font-weight: bold;
}

.texto-atividade {
  margin-left: 15px;
}
</style>
